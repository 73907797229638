import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["GOERLI"] = 5] = "GOERLI";
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["opBNB"] = 204] = "opBNB";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
    ChainId[ChainId["ARB_TESTNET"] = 421613] = "ARB_TESTNET";
    ChainId[ChainId["ARB"] = 42161] = "ARB";
    ChainId[ChainId["ZKSYNC"] = 324] = "ZKSYNC";
    ChainId[ChainId["ZKSYNC_TESTNET"] = 280] = "ZKSYNC_TESTNET";
    ChainId[ChainId["SCROLL_TESTNET"] = 534351] = "SCROLL_TESTNET";
    ChainId[ChainId["SCROLL"] = 534352] = "SCROLL";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.GOERLI, "0xa373C0460cD7c1A355E07a004c8f8651aDE8a3d3"), _define_property(_obj, ChainId.BSC, "0xD4EBc57fA5635EC1160F80962d935d44DdCED916"), _define_property(_obj, ChainId.BSC_TESTNET, "0x0c5843163d0Fbe1c3106C5f96f202ca08D91F4b7"), _define_property(_obj, ChainId.ARB_TESTNET, "0x6941CD0d6EF3E4c1294b85fe6EF275AA6C4691fb"), _define_property(_obj, ChainId.ZKSYNC, ""), _define_property(_obj, ChainId.opBNB, "0x837A7dBb71aa66f155642d619c08f5C11e73dCe8"), _define_property(_obj, ChainId.SCROLL_TESTNET, "0x4CDE53B04082CDAAaD1fCF4fF5e54693C7254D19"), _define_property(_obj, ChainId.SCROLL, "0x11F174962dF86393faB25bf6EdB3d084e51FFAf9"), _obj);
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.GOERLI, "0xbd6e9c8068984bfca91aed95f2f98658a71e81a7e6fdc3ee14a32e18282b6fd6"), _define_property(_obj1, ChainId.BSC, "0xa128b758fe3db46bdb4f32a8deaf22bbaa0a7b6f3cbaff9500cd9bc5aa31b595"), _define_property(_obj1, ChainId.BSC_TESTNET, "0x9d15c1fe3a61ecfd4282672e26382de61be672a5316cfef0c6362c7898c7b6d7"), _define_property(_obj1, ChainId.ARB_TESTNET, "0xddb43bdccbba1a5f8ed99fdaccbafbcb4292989533b27066454dccc7f50ff467"), _define_property(_obj1, ChainId.opBNB, "0x33452d76f30738d058c15cb9e9f2547a7085b5c3c4090d0806ba0c58520a5d7e"), _define_property(_obj1, ChainId.SCROLL_TESTNET, "0x05ae4e582d3dfde23af089aea9c8b019647888cabcc2aa3cb68fcc5a472935e2"), _define_property(_obj1, ChainId.SCROLL, "0x6bf409838ed39aab678b3900f73838ac10e90eda0a1d325200da94cf79ead359"), _obj1);
export var TREASURY_ADDRESS = _define_property({}, ChainId.opBNB, "0xB49a81Ce0F950Fa00910d73309F996CA6CEC3575");
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// 动态更新 FEES_NUMERATOR 的函数
var FEES_NUMERATOR = JSBI.BigInt(9970);
function listenForFeeChanges(callback) {
    if ("object" !== "undefined") {
        var handleFeeChange = function handleFeeChange(event) {
            var fee = event.detail;
            if (typeof callback === "function") {
                callback(fee);
            }
        };
        window.addEventListener("onFee", handleFeeChange);
    } else {
        console.log("window is not available in this environment.");
    }
}
function handleFeeUpdate(fee) {
    try {
        // FEES_NUMERATOR = 10000 - fee * 10
        var feeBigInt = JSBI.BigInt(fee);
        var tenTimesFee = JSBI.multiply(feeBigInt, JSBI.BigInt(10));
        FEES_NUMERATOR = JSBI.subtract(JSBI.BigInt(10000), tenTimesFee);
    } catch (error) {
        console.error("Error updating FEES_NUMERATOR:", error);
    }
}
listenForFeeChanges(handleFeeUpdate);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export { FEES_NUMERATOR };
export var FEES_DENOMINATOR = JSBI.BigInt(10000);
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType[SolidityType["uint256"] = 0] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
